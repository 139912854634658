<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Header: Personal Info -->
        <div class="d-flex my-2">
          <feather-icon icon="InfoIcon" size="19" />
          <h5 class="mb-0 ml-50">Thông tin</h5>
        </div>
        <validation-observer ref="signupRef" #default="{ valid }">
          <!-- Form: Personal Info Form -->
          <b-form class="mt-1">
            <b-row>
              <b-col cols="12" md="12">
                <b-form-group
                  label="Vai trò"
                  label-for="title"
                  label-class="mb-1"
                >
                  <b-form-radio-group
                    id="title"
                    v-model="roleId"
                    :options="roleOptions"
                    @change="handleChangeRadio"
                  />
                </b-form-group>
              </b-col>
              <!-- Field:  fullName -->
              <b-col cols="12" md="4">
                <b-form-group label="Họ tên" label-for="fullName">
                  <validation-provider
                    #default="{ errors }"
                    name="fullName"
                    vid="fullName"
                    rules="required"
                  >
                    <b-form-input id="fullName" v-model="fullName" />
                    <small class="text-danger">{{
                      errors.length > 0 ? "Họ tên không hợp lệ!" : null
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- phone number -->
              <b-col cols="12" md="4">
                <b-form-group label="Số điện thoại" label-for="phone">
                  <validation-provider
                    #default="{ errors }"
                    name="phoneNumber"
                    vid="phoneNumber"
                    :rules="{
                      required: true,
                      regex: /^(0|\+)[0-9]{8,14}$/,
                    }"
                  >
                    <b-form-input id="phone" v-model="phoneNumber" />
                    <small class="text-danger">{{
                      errors.length > 0 ? "Số điện thoại không hợp lệ!" : null
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: DOB -->
              <b-col cols="12" md="4">
                <b-form-group label="Ngày sinh" label-for="dob">
                  <validation-provider
                    name="dob"
                    vid="dob"
                    rules="required"
                    #default="{ errors }"
                  >
                    <flat-pickr
                      v-model="dob"
                      class="form-control"
                      :config="{ dateFormat: 'd-m-Y' }"
                    />
                    <small class="text-danger">{{
                      errors[0] && "Ngày sinh không hợp lệ."
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <div class="d-flex my-2">
              <feather-icon icon="UserIcon" size="19" />
              <h5 class="mb-0 ml-50">Tài khoản</h5>
            </div>
            <b-row>
              <!-- Field: email -->
              <b-col cols="12" md="4">
                <b-form-group label="Email" label-for="email">
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input id="email" v-model="email" />
                    <small class="text-danger">{{
                      errors.length > 0 ? "Email không hợp lệ!" : null
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: User Name -->
              <b-col cols="12" md="4">
                <b-form-group label="Tên người dùng" label-for="username">
                  <validation-provider
                    #default="{ errors }"
                    name="username"
                    vid="username"
                    :rules="{
                      required: true,
                      regex:
                        /^(?=[a-zA-Z0-9\\.@_-]{6,32})([a-zA-Z0-9]([.@_-]?[a-zA-Z0-9])*)$/,
                    }"
                  >
                    <b-form-input id="username" v-model="username" />
                    <small class="text-danger">{{
                      errors.length > 0 ? "Tên người dùng không hợp lệ!" : null
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: password -->
              <b-col cols="12" md="4">
                <b-form-group label="Mật khẩu" label-for="password">
                  <validation-provider
                    #default="{ errors }"
                    name="password"
                    vid="password"
                    :rules="{
                      required: true,
                      regex: /^(?=.*[0-9])(?=.*[a-zA-Z]).{6,25}$/,
                    }"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        v-model="password"
                        :type="userPassword"
                        name="password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePassword"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{
                      errors[0] &&
                      "Mật khẩu phải từ 6 đên 25 kí tự và có ít nhất 1 chữ và số."
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <div v-if="roleId == RoleEnum.CustomerSupport" class="d-flex my-2">
              <feather-icon icon="InfoIcon" size="19" />
              <h5 class="mb-0 ml-50">Chiết khấu</h5>
            </div>
            <b-row v-if="roleId == RoleEnum.CustomerSupport">
              <b-col cols="12" md="4">
                <b-form-group label="Chiết khấu" label-for="exchangeRate">
                  <validation-provider
                    #default="{ errors }"
                    name="discount"
                    vid="discount"
                    rules="required|decimal:2"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        v-model="discount"
                        name="discount"
                        placeholder=""
                      />
                      <b-input-group-append is-text>%</b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{
                      errors[0] && "Tỷ giá không hợp lệ."
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col class="d-flex justify-content-end">
                <b-button
                  :disabled="!valid"
                  variant="primary"
                  class="mb-1 mb-sm-0"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click.prevent="handleSubmitForm"
                >
                  <Spinner v-if="loading" />
                  <template>Tạo</template>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
  </div>
</template>

<script>
import { RoleEnum } from "@/@core/utils/enums/user.enum";
import useToastHandler from "@/services/toastHandler";
import AutoCompleteInput from "@core/components/AutoCompleteInput";
import Spinner from "@core/components/Spinner";
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BInputGroup,
  BInputGroupAppend,
  BRow,
} from "bootstrap-vue";
import "cleave.js/dist/addons/cleave-phone.us";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Cleave from "vue-cleave-component";
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    BFormRadioGroup,
    BButton,
    AutoCompleteInput,
    Spinner,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      username: null,
      password: null,
      phoneNumber: null,
      email: null,
      fullName: null,
      dob: null,
      discount: undefined,
      roleId: RoleEnum.Admin,
      userPassword: "password",
    };
  },

  computed: {
    ...mapState({
      loading: (s) => s.apps.users.signup.loading,
    }),
    passwordToggleIcon() {
      return this.userPassword === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    ...mapActions({
      signup: "users/signup/signup",
    }),
    togglePassword() {
      this.userPassword =
        this.userPassword === "password" ? "text" : "password";
    },
    async handleSubmitForm() {
      const res = await this.signup({
        username: this.username,
        fullName: this.fullName,
        password: this.password,
        dob: this.dob,
        phoneNumber: this.phoneNumber,
        email: this.email,
        roleId: this.roleId,
        discount: Number(this.discount),
      });
      if (!res) return;
      this.showToastSuccess(null, "Tạo tài khoản thành công");
      this.$router.push({ name: "user-list" });
    },
    handleChangeRadio() {
      this.discount = undefined;
    },
  },
  setup() {
    const roleOptions = [
      { text: "Quản trị viên", value: RoleEnum.Admin },
      { text: "Cộng tác viên", value: RoleEnum.CustomerSupport },
    ];

    const { showToastSuccess } = useToastHandler();

    return {
      roleOptions,
      showToastSuccess,
      RoleEnum,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
