var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('div',{staticClass:"d-flex my-2"},[_c('feather-icon',{attrs:{"icon":"InfoIcon","size":"19"}}),_c('h5',{staticClass:"mb-0 ml-50"},[_vm._v("Thông tin")])],1),_c('validation-observer',{ref:"signupRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('b-form',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Vai trò","label-for":"title","label-class":"mb-1"}},[_c('b-form-radio-group',{attrs:{"id":"title","options":_vm.roleOptions},on:{"change":_vm.handleChangeRadio},model:{value:(_vm.roleId),callback:function ($$v) {_vm.roleId=$$v},expression:"roleId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Họ tên","label-for":"fullName"}},[_c('validation-provider',{attrs:{"name":"fullName","vid":"fullName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fullName"},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors.length > 0 ? "Họ tên không hợp lệ!" : null))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Số điện thoại","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"phoneNumber","vid":"phoneNumber","rules":{
                    required: true,
                    regex: /^(0|\+)[0-9]{8,14}$/,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors.length > 0 ? "Số điện thoại không hợp lệ!" : null))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Ngày sinh","label-for":"dob"}},[_c('validation-provider',{attrs:{"name":"dob","vid":"dob","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ dateFormat: 'd-m-Y' }},model:{value:(_vm.dob),callback:function ($$v) {_vm.dob=$$v},expression:"dob"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] && "Ngày sinh không hợp lệ."))])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"d-flex my-2"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"19"}}),_c('h5',{staticClass:"mb-0 ml-50"},[_vm._v("Tài khoản")])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors.length > 0 ? "Email không hợp lệ!" : null))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Tên người dùng","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"username","vid":"username","rules":{
                    required: true,
                    regex:
                      /^(?=[a-zA-Z0-9\\.@_-]{6,32})([a-zA-Z0-9]([.@_-]?[a-zA-Z0-9])*)$/,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors.length > 0 ? "Tên người dùng không hợp lệ!" : null))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Mật khẩu","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"password","vid":"password","rules":{
                    required: true,
                    regex: /^(?=.*[0-9])(?=.*[a-zA-Z]).{6,25}$/,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"type":_vm.userPassword,"name":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePassword}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] && "Mật khẩu phải từ 6 đên 25 kí tự và có ít nhất 1 chữ và số."))])]}}],null,true)})],1)],1)],1),(_vm.roleId == _vm.RoleEnum.CustomerSupport)?_c('div',{staticClass:"d-flex my-2"},[_c('feather-icon',{attrs:{"icon":"InfoIcon","size":"19"}}),_c('h5',{staticClass:"mb-0 ml-50"},[_vm._v("Chiết khấu")])],1):_vm._e(),(_vm.roleId == _vm.RoleEnum.CustomerSupport)?_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Chiết khấu","label-for":"exchangeRate"}},[_c('validation-provider',{attrs:{"name":"discount","vid":"discount","rules":"required|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"name":"discount","placeholder":""},model:{value:(_vm.discount),callback:function ($$v) {_vm.discount=$$v},expression:"discount"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_vm._v("%")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] && "Tỷ giá không hợp lệ."))])]}}],null,true)})],1)],1)],1):_vm._e(),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mb-1 mb-sm-0",attrs:{"disabled":!valid,"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmitForm.apply(null, arguments)}}},[(_vm.loading)?_c('Spinner'):_vm._e(),[_vm._v("Tạo")]],2)],1)],1)],1)]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }